<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs10 sm8 md8>
                <v-card class="elevation-4" id="UpdatePassCard">
                    <transition name="fade-transition"><v-alert
                        border="left"
                        color="red lighten-2"
                        dark
                        v-if="!!responseMsg"
                    >
                        {{responseMsg}}
                    </v-alert></transition>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field
                                prepend-icon="lock"
                                color="#777BD2"
                                name="password"
                                label="New Password"
                                id="new_password"
                                required
                                v-model="new_password"
                                :rules="passwordRules"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                            >
                            </v-text-field>

                            <v-text-field
                                prepend-icon="lock"
                                color="#777BD2"
                                name="confirmPassword"
                                label="Confirm Password"
                                id="confirmPassword"
                                required
                                v-model="confirmPassword"
                                :rules="confirmPasswordRule"
                                :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword1 ? 'text' : 'password'"
                                @click:append="showPassword1 = !showPassword1"
                            >
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn elevation="0" id="updatePassBtn" :disabled="updatePasswordInProgress" @click="submit" class="primary"><v-progress-circular
                            indeterminate
                            color="#fff"
                            v-if="updatePasswordInProgress"
                            class="progressLoader"
                        ></v-progress-circular><span v-if="!updatePasswordInProgress">Update Password</span></v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: 'UpdatePassword',
    data(){
        return{
            new_password: '',
            confirmPassword: '',
            valid: false,
            passwordRules: [
                v => !!v || 'Password is required',
                v => v.length >= 6 ||
                    'Password must be greater than 6 characters',
                v => v.match(/[A-Z]/) != null || 'Password should have atleast one uppercase character',
                v => v.match(/[@$!%*#?&]/) != null || 'Password should have atleast one special character'
            ],
            confirmPasswordRule: [
                v => !!v || 'Type password again',
                v => v == this.new_password || 'Password does not match'
            ],
            showPassword: false,
            showPassword1: false,
        }
    },
    mounted(){
        if(this.$route.params.temporaryUser == undefined || this.$route.params.temporaryUser == null){
            this.$router.push({path: '/sign-in'});
        }
    },
    methods:{
        submit() {
            this.$store.state.updatePasswordInProgress = true;
            if (this.$refs.form.validate()) {
                this.$store.dispatch('updatePassword', {
                    user: this.$route.params.temporaryUser,
                    newPassword: this.new_password
                });
            }
            else{
                this.$store.state.updatePasswordInProgress = false;
            }
        },
    },
    computed:{
        responseMsg(){
            return this.$store.state.responseMsg;
        },
        updatePasswordInProgress(){
            return this.$store.state.updatePasswordInProgress;
        }
    }
}
</script>
<style scoped>
button#updatePassBtn{
    margin: auto;
    padding: 20px 60px;
    background-color: #777BD2;
    color: #fff;
}
button#updatePassBtn{
    min-width: 240px;
}
@media screen and (min-width: 960px){
    #UpdatePassCard{
        padding: 50px 90px 60px;
    }  
}
@media screen and (max-width: 959px) and (min-width: 600px){
    #UpdatePassCard{
        padding: 15px 30px 30px;
    }  
}
@media screen and (max-width: 600px){
    #UpdatePassCard{
        padding: 5px 30px 20px;
    }  
}
</style>